<template functional>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 128 128" style="enable-background:new 0 0 128 128;" xml:space="preserve">
    <linearGradient id="hand_2_" gradientUnits="userSpaceOnUse" x1="16.3982" y1="53.0709" x2="16.3982" y2="85.5709" gradientTransform="matrix(1 0 0 -1 0 128)">
      <stop offset="0.5" class="entity-missing-skin"/>
      <stop offset="1" class="entity-missing-skin"/>
    </linearGradient>
    <path id="hand_1_" class="entity-missing-skin" d="M26.28,62.35c-2.18-1.62-4.22-3.51-6.08-5.66c-0.95-1.05-2.83-2.78-4.69-2.62  c-0.38,0.03-0.87,0.16-1.34,0.51c-1.27-1.64-4.2-6.04-5.66-9.92c-0.59-1.23-1.62-1.43-2.33-1.23C4.92,43.8,4.11,45.4,4.3,47.17  c0.62,5.9,5.24,13.09,6.97,14.78l-0.03,0.02c3.4,4.14,3.72,10.18,3.72,10.24c0.02,0.47,0.37,0.86,0.83,0.94l9.51,1.59  c0.06,0.01,0.11,0.01,0.17,0.01c0.42,0,0.81-0.27,0.94-0.68c0.63-1.85,2.09-6.25,2.11-7.25C28.58,63.9,26.73,62.62,26.28,62.35z"/>
    <linearGradient id="hand_3_" gradientUnits="userSpaceOnUse" x1="111.8223" y1="53.0709" x2="111.8223" y2="85.5709" gradientTransform="matrix(1 0 0 -1 0 128)">
      <stop offset="0.5" class="entity-missing-skin"/>
      <stop offset="1" class="entity-missing-skin"/>
    </linearGradient>
    <path id="hand" class="entity-missing-skin" d="M122.08,43.45c-0.71-0.22-1.77-0.02-2.41,1.31c-1.43,3.8-4.36,8.19-5.63,9.83  c-0.47-0.35-0.96-0.47-1.34-0.51c-1.86-0.16-3.74,1.57-4.7,2.63c-1.85,2.13-3.89,4.03-6.07,5.64c-0.45,0.27-2.3,1.54-2.24,4.48  c0.02,1,1.48,5.41,2.11,7.25c0.14,0.41,0.52,0.68,0.94,0.68c0.06,0,0.11,0,0.17-0.01l9.51-1.59c0.51-0.08,0.87-0.54,0.83-1.06  c0-0.05-0.34-5.39,3.71-10.11l-0.03-0.02c1.73-1.68,6.2-9.03,6.98-14.77C124.15,45.45,123.36,43.83,122.08,43.45z"/>
    <g id="shirt">
      <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="64.0675" y1="44.5709" x2="64.0675" y2="1.8179" gradientTransform="matrix(1 0 0 -1 0 128)">
        <stop offset="0" class="entity-missing-shirt"/>
        <stop offset="0.4701" class="entity-missing-shirt"/>
        <stop offset="1" class="entity-missing-shirt"/>
      </linearGradient>
      <path class="entity-missing-shirt" d="M115.64,70.51c-0.02-0.8-0.65-1.45-1.45-1.51l-12.81-0.75c-0.04,0-0.07,0-0.11,0   c-0.78,0-1.45,0.59-1.54,1.38L97.05,88.6c-4.18-3.58-9.95-6.21-17.88-6.21c-2.61,0-27.58-0.12-30.2-0.12   c-7.93,0-13.7,2.63-17.88,6.21l-2.67-18.97c-0.09-0.79-0.76-1.38-1.55-1.38c-0.04,0-0.07,0-0.11,0l-12.81,0.75   c-0.8,0.06-1.42,0.71-1.45,1.51l-0.11,40.54c-0.14,7.11,5.58,12.94,12.69,12.94c4.92,0,8.86-3.69,9.46-8.31l0.03,0.02l0,8.41h1.5   h1.5h52.98h1.5h1.5l0-8.29l0.04-0.02c0.6,4.62,4.54,8.31,9.46,8.31c7.11,0,12.83-5.84,12.69-12.94L115.64,70.51z"/>
      <path class="entity-missing-shirt" d="M99.06,123.1c-0.59-0.26-1.14-0.58-1.65-0.97c-0.71-0.53-1.35-1.16-1.89-1.86   c-1.02-1.31-1.69-2.89-1.91-4.59l-0.02,0.01l-0.38-10.68C93.21,105.01,93.84,114.16,99.06,123.1z"/>
      <path class="entity-missing-shirt" d="M34.93,105.01l-0.38,10.59v0.01c-0.43,3.23-2.5,6-5.39,7.36C34.3,114.07,34.93,105.01,34.93,105.01z   "/>
    </g>
    <path id="head" class="entity-missing-skin" d="M57.28,10.99L57.28,10.99L57.28,10.99C41.44,12.92,28.84,31.63,31.81,56  c2.95,24.23,19.51,34.38,34.9,32.51s29.04-15.69,26.09-39.93C89.83,24.21,73.11,9.07,57.28,10.99z"/>
    <path id="mouth" class="entity-missing-hair" d="M71.05,75.72c0.22,2.78-2.21,3.55-5.27,3.79c-3.06,0.24-5.58-0.21-5.8-2.92  c-0.22-2.86,2.12-4.82,5.17-5.06C68.21,71.29,70.82,72.81,71.05,75.72z"/>
    <path id="nose" class="entity-missing-hair" d="M67.1,62.41c-0.1-0.03-0.2-0.04-0.3-0.04l-6.44,0.78c-0.1,0.02-0.19,0.06-0.28,0.11  c-0.56,0.31-0.8,0.95-0.45,1.56c0.35,0.61,1.85,2.26,4.44,1.95c2.58-0.31,3.64-2.28,3.84-2.95C68.1,63.14,67.71,62.58,67.1,62.41z"/>
    <g id="eyes">
      
        <ellipse transform="matrix(0.9929 -0.1193 0.1193 0.9929 -6.3943 6.075)" class="entity-missing-hair" cx="47.55" cy="56.45" rx="4.49" ry="4.65"/>
      
        <ellipse transform="matrix(0.9929 -0.1193 0.1193 0.9929 -5.7549 9.6015)" class="entity-missing-hair" cx="77.32" cy="52.87" rx="4.49" ry="4.65"/>
    </g>
    <g id="eyebrows">
      <path class="entity-missing-hair" d="M81.77,45c-2.7-0.8-5.4-0.93-8.06,0.15c-0.87,0.35-1.82,1.2-2.78,1.29   c-0.69,0.07-1.36-0.39-1.54-1.07c-0.16-0.62,0.15-1.14,0.49-1.64c1.95-2.85,5.76-4.1,9.08-3.45c1.48,0.29,3.1,0.97,4.14,2.11   C84.08,43.47,83.46,45.46,81.77,45z"/>
      <path class="entity-missing-hair" d="M39.6,47.91c1.62-3.03,5.4-4.43,8.69-4.08c1.49,0.16,2.97,0.7,4.17,1.62   c0.91,0.7,2.08,2.14,0.61,3.04c-0.93,0.56-2.41-0.21-3.35-0.37c-1.35-0.22-2.74-0.19-4.07,0.1c-1.36,0.29-2.57,0.83-3.76,1.53   C40.63,50.47,38.81,49.47,39.6,47.91z"/>
    </g>
    <g id="hairGroup">
      <path id="hair_7_" class="entity-missing-hair" d="M56.56,4.43L56.56,4.43c-0.01,0-0.01,0-0.02,0c-0.01,0-0.01,0-0.02,0v0   c-41.69,5.2-27.47,51.83-27.47,51.83s2.46,4.69,3.57,6.76c0.16,0.3,0.61,0.21,0.64-0.13c0.42-4.09,1.94-18.6,3.04-23.09   c0.65-2.64,3.1-4.43,5.82-4.26c4.18,0.25,10.8,0.4,17.99-0.46c0.03,0,0.05-0.01,0.08-0.01s0.05-0.01,0.08-0.01   c7.19-0.86,13.59-2.55,17.59-3.78c2.6-0.8,5.4,0.36,6.66,2.78c2.12,4.09,6.98,17.76,8.36,21.69c0.11,0.32,0.57,0.3,0.66-0.03   l1.9-7.37C95.45,48.35,98.31-0.32,56.56,4.43z"/>
      <path id="hairHighlights" style="fill:url(#hairHighlights_1_);" d="M95.45,48.35c0,0,2.87-48.67-38.88-43.92v0   c-0.01,0-0.01,0-0.01,0h0c-0.01,0-0.01,0-0.01,0l0,0c-0.65,0.08-1.28,0.17-1.9,0.27c-1.24,0.2-2.42,0.44-3.56,0.71   c-0.01,0-0.02,0.01-0.03,0.01c-0.08,0.02-0.16,0.04-0.24,0.06C16.2,14.1,29.07,56.26,29.07,56.26l3.57,6.72   c0.16,0.3,0.6,0.21,0.64-0.12c0.42-4.13,1.94-18.58,3.04-23.06c0.65-2.64,3.1-4.43,5.82-4.26c4.18,0.25,10.8,0.4,17.99-0.46   c0.03,0,0.05-0.01,0.08-0.01s0.05-0.01,0.08-0.01c7.19-0.86,13.59-2.55,17.59-3.78c2.6-0.8,5.4,0.36,6.66,2.78   c2.13,4.11,7.02,17.88,8.38,21.74c0.11,0.32,0.56,0.3,0.65-0.03C94.15,53.51,95.45,48.35,95.45,48.35z"/>
    </g>
  </svg>
</template>

<script>
export default {}
</script>
